// import 'virtual:svg-icons-register';
import './style.scss';
import 'vite/modulepreload-polyfill';

/**
 * TODO: Create components array via a Vite plugin that parses the components
 * folders and returns the component names which have a .ts file.
 */

// Detects if a component is used on the page and initializes it.
const components = [
  '.accordion',
  '.teaser-slider',
  '.stage-m',
  '.footer',
  '.friendly-captcha',
  '.header',
  '.main-nav',
  '.iframe',
  '.form',
  '.sharing',
  '.table',
  '.video',
  '.jumpmarks',
  '.gallery-slider',
  '.banner',
  '.overview',
];

async function init() {
  console.log('[initialized] main.ts');
  components.forEach(async (component: any) => {
    if (document.querySelector(component)) {
      console.log(`[detected] ${component} component`);
    }
    switch (component) {
      case '.accordion':
        if (!document.querySelector(component)) break;
        const accordion = await import('./src/components/accordion/accordion');
        accordion.init();
        break;
      case '.teaser-slider':
        if (!document.querySelector(component)) break;
        const teaserSlider = await import(
          './src/components/teaser-slider/teaser-slider'
        );
        teaserSlider.init();
        break;
      case '.stage-m':
        if (!document.querySelector(component)) break;
        const stageM = await import('./src/components/stage-m/stage-m');
        stageM.init();
        break;
      case '.footer':
        if (!document.querySelector(component)) break;
        const footer = await import('./src/components/footer/footer');
        footer.init();
        break;
      case '.friendly-captcha':
        if (!document.querySelector(component)) break;
        const friendlyCaptcha = await import(
          './src/components/friendly-captcha/friendly-captcha'
        );
        friendlyCaptcha.init();
        break;
      case '.header':
        if (!document.querySelector(component)) break;
        const header = await import('./src/components/header/header');
        header.init();
        break;
      case '.main-nav':
        if (!document.querySelector(component)) break;
        const mainNav = await import('./src/components/main-nav/main-nav');
        mainNav.init();
        break;
      case '.iframe':
        if (!document.querySelector(component)) break;
        const iframe = await import('./src/components/iframe/iframe');
        iframe.init();
        break;
      case '.form':
        if (!document.querySelector(component)) break;
        const form = await import('./src/components/form/form');
        form.init();
        break;
      case '.sharing':
        if (!document.querySelector(component)) break;
        const sharing = await import('./src/components/sharing/sharing');
        sharing.init();
        break;
      case '.table':
        if (!document.querySelector(component)) break;
        const table = await import('./src/components/table/table');
        table.init();
        break;
      case '.video':
        if (!document.querySelector(component)) break;
        const video = await import('./src/components/video/video');
        video.init();
        break;
      case '.jumpmarks':
        if (!document.querySelector(component)) break;
        const jumpmarks = await import('./src/components/jumpmarks/jumpmarks');
        jumpmarks.init();
        break;
      case '.gallery-slider':
        if (!document.querySelector(component)) break;
        const gallerySlider = await import(
          './src/components/gallery-slider/gallery-slider'
        );
        gallerySlider.init();
        break;
      case '.banner':
        if (!document.querySelector(component)) break;
        const banner = await import('./src/components/banner/banner');
        banner.init();
        break;
      case '.overview':
        if (!document.querySelector(component)) break;
        const overview = await import(
          './src/components/overview/overview'
        );
        overview.init();
        break;
      default:
        break;
    }

    /**
     * TODO: plugin that returns dynamically created ready code.
     */
    // Dynamic importing of the component, if component is detected on the page
    // The folder name and file name of the component has to be the same as the class name
    // if (!document.querySelector(component)) return;
    // console.log(`[detected] ${component} component`);
    // try {
    //   const name = component.replace('.', '');
    //   const module = await import(/* @vite-ignore */`./src/components/${name}/${name}`);
    //   if (module && typeof module.init === 'function') {
    //     module.init();
    //   }
    // } catch (e) {
    //   console.log(e);
    //   console.log(`[error] error while loading ${component}`);
    // }
  });
}

init();
